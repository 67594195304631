import '../index.scss'; // General Navbar Styles
import './index.scss'; // UnSigned NavBar Styles

import React from 'react';
import { useNavigate } from 'react-router-dom';

import Logo from '../../Logo';

const UnSignedNavBar : React.FC = () => {
    const navigate = useNavigate();

    return (
        <div className='navbar unsigned'>
            <Logo onClick={ () => navigate('/') }/>

            <div className='menu-container box-shadow'>
                <div className='menu-item'>
                    <div className='header'>Features {`>`}</div>
                </div>
                
                <div className='menu-item'>
                    <div className='header'>Plans {`>`}</div>
                </div>

                <div className='menu-item'>
                    <div className='header'>Resources {`>`}</div>
                </div>
            </div>

            <div className='buttons-container'>
                <div className='button box-shadow' onClick={ () => navigate('/sign-up') } >Sign Up</div>

                <div className='button sales box-shadow' onClick={ () => navigate('/sales') } >Talk To Sales</div>
            </div>
        </div>
    )
}

export default UnSignedNavBar;
