import './index.scss';

import React, { useState, useEffect } from 'react';

type UnReleasedProps = {
    date: Date;
}

const calculateTimeLeft = ( date: Date ) => {
    const now = new Date().getTime();
    const distance = date.getTime() - now;

    if ( distance <= 0 ) return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    
    return {
        days: Math.floor( distance / ( 1000 * 60 * 60 * 24 )),
        hours: Math.floor( ( distance % ( 1000 * 60 * 60  * 24 )) / ( 1000 * 60 * 60 )),
        minutes: Math.floor( ( distance % ( 1000 * 60 * 60 )) / ( 1000 * 60 )),
        seconds: Math.floor( ( distance % ( 1000 * 60)) / 1000 )
    }
}

const UnReleased: React.FC<UnReleasedProps> = ({ date }) => {
    const [ timeLeft, setTimeLeft ] = useState( calculateTimeLeft( date ) );

    useEffect( () => {
        const intervalId = setInterval( () => {
            setTimeLeft( calculateTimeLeft( date ) );
        }, 1000);

        return () => clearInterval( intervalId );
    }, [ date ]);

    return (
        <div className='unreleased'>
            <img src='/login-page.png' alt='hey-you' />
            <div className='container'>
                <div className='title'>We are so glad how excited you are about our platform!!! But this isn't officially released yet.</div>
                <div className='date-text'>It will be released in:</div>
                <div className='date'>
                    Days: { timeLeft.days } Hours: { timeLeft.hours } Minutes: { timeLeft.minutes } Seconds: { timeLeft.seconds }
                </div>
            </div>
        </div>
    )
}

export default UnReleased;
