// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar .logo {
  background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
  font-size: 3vw !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  cursor: pointer; }
`, "",{"version":3,"sources":["webpack://./src/Components/NavBar/index.scss"],"names":[],"mappings":"AAAA;EAEQ,oEAAoE;EACpE,yBAAyB;EACzB,2BAA2B;EAC3B,8BAA8B;EAC9B,yBAAyB;EACzB,0BAA0B;EAC1B,eAAe,EAAA","sourcesContent":[".navbar {\n    .logo {\n        background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);\n        font-size: 3vw !important;\n        margin-top: auto !important;\n        margin-bottom: auto !important;\n        margin-left: 0 !important;\n        margin-right: 0 !important;\n        cursor: pointer;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
