import './index.scss';

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import UnSignedNavbar from '../../Components/NavBar/Unsigned';

import Logo from '../../Components/Logo';

import Connection from "../../Components/svgs/Connection";
import Analysis from "../../Components/svgs/Analysis";
import RelationshipHeart from "../../Components/svgs/RelationshipHeart";
import Calender from "../../Components/svgs/Calender";
import Community from "../../Components/svgs/Community";
import Mail from '../../Components/svgs/Mail';

import { useMakeContact } from "../../hooks";

import { ImageOverlay } from './ImageOverlay';

const Home: React.FC = () => {
    const navigate = useNavigate();
    const [ email, setEmail ] = useState<string>("");
    const { reload, makeContact } = useMakeContact();

    useEffect( () => {
        if ( reload === true ) window.location.reload();
    }, [ reload ]);

    return (
        <div className='Home'>
            <div className='header gray-background'>
                <UnSignedNavbar />

                <div className='content'>
                    <Logo />

                    <div className='logo title'>
                        The New Way To Buy, And Sell Tickets
                    </div>

                    <div className='desc'>
                        Built By Organizers For Organizers. Everything You Will Need To, Create, Manage, & Sell Your Events.
                    </div>

                    <div className='button-container'>
                        <div className='button box-shadow' onClick={ () => navigate('/sales') } >Talk to Sales</div>
                        
                        <div className='button transparent box-shadow' onClick={ () => navigate('/sign-up') } >Get Started</div>
                    </div>
                </div>
            </div>

            <ImageOverlay />

            <div className='our-services gray-background'>
                <div className='title'>OUR SERVICES</div>

                <div className='split-text'>
                    <div className="split-text-container">
                        <div className='title'>
                            <div className='text'>
                                WHAT WE OFFER ON
                            </div>
                            <div className='text-platform'>OUR PLATFORM</div>
                        </div>

                        <div className='card-offer box-shadow'>
                            <Connection stroke={"#DC257D"} />
                            <div className="title">Connection</div>
                            <div className='description'>
                                Everything event organizers will possibly need for their events. From venues to vendors, service providers and even an active user base to market their event to.
                            </div>
                        </div>

                        <div className='card-offer box-shadow'>
                            <Analysis stopColor1="#000092" stopColor2="#FF00F3" />
                            <div className="title">Ticket Management</div>
                            <div className='description'>
                                A place to sell your tickets, and a user base to buy them, while also allowing for analysis on tickets and allow you to check in users for your event.
                            </div>
                        </div>

                    </div>

                    <div className="split-text-container">
                        <div className='card-offer box-shadow'>
                            <RelationshipHeart stopColor1="#1EC4FF" stopColor2="#2CB8FF" stopColor3="#539BFF" stopColor4="#916DFF" stopColor5="#B255FF" />
                            <div className="title">Relationship</div>
                            <div className='description'>
                                Great place to foster long lasting relationships between event organizers and event goers, through post, messaging plus more.
                            </div>
                        </div>

                        <div className='card-offer box-shadow'>
                            <Calender fill="#292D32" stopColor1="#F655EF" stopColor2="#FB4F8C" />
                            <div className="title">Fun Events</div>
                            <div className='description'>
                                Awesome place to find great events near you, from pop up shops, to concerts, and even sororities parties. Everything you need to fill up your calendar
                            </div>
                        </div>

                        <div className='card-offer box-shadow'>
                            <Community stopColor1="#2B2F82" stopColor2="#BF509D" />
                            <div className="title">Community</div>
                            <div className='description'>
                                Awesome place to find great events near you, from pop up shops, to concerts, and even sororities parties. Everything you need to fill up your calendar
                            </div>
                        </div>
                    </div>    
                </div>
            </div>

            <div className='more-questions'>
                <div className='form'>
                    <div className='title'>Still Have Questions?</div>
                    <div className='desc'>Enter your email and we will reach out to you to set up a demo.</div>

                    <div className='email-form box-shadow'>
                        <Mail fill='white'/>
                        <input type='email' placeholder='Enter E-Mail Address' value={email} onChange={ (e) => setEmail( e.target.value )}/>
                        <div className='button box-shadow' onClick={ () => {
                            makeContact({ first_name: "Guest", last_name: "Guest", message: "Had more question from organizer site", email, phone_number: "" });
                        }}>Submit</div>
                    </div>
                </div>

                <img src='/sales-people.png' alt='sales' />
            </div>
        </div>
    )
}

export default Home;
