// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Expletus+Sans:ital,wght@0,400..700;1,400..700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Libre+Barcode+128+Text&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box-shadow {
  box-shadow: 2px 12px 20px rgba(0, 0, 0, 0.25); }

.MainPage {
  display: flex;
  flex-direction: row; }
`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAIA;EACI,6CAA6C,EAAA;;AAGjD;EACI,aAAa;EACb,mBAAmB,EAAA","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Expletus+Sans:ital,wght@0,400..700;1,400..700&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Libre+Barcode+128+Text&display=swap');\n\n.box-shadow {\n    box-shadow: 2px 12px 20px rgba(0, 0, 0, 0.25);\n}\n\n.MainPage {\n    display: flex;\n    flex-direction: row;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
