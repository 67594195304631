export const makeContact = `
    query makeContact( $contactInput: ContactInput! ) {
        makeContact( contactInput: $contactInput )
    }
`;

export const joinWaitlist = `
mutation JoinWaitlist($waitlistInput: WaitListInput!) {
    joinWaitlist(waitlistInput: $waitlistInput)
}
`;
