import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLogin } from '../hooks/useLogin';

const SignOut: React.FC = () => {
    const navigate = useNavigate();
    const { signOut } = useLogin();

    useEffect( () => {
        signOut();

        navigate('/login')
    }, [ ]);

    return <></>;
}

export default SignOut;
