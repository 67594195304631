import React, { useState } from 'react';

export const ImageOverlay: React.FC = () => {
    const [ images, setImages ] = useState<{
        position: "left-top" | "left-bottom" | "right-top" | "right-bottom" | "center",
        src: string,
        focus: boolean
    }[]>([
        {
            position: 'left-top',
            src: '/prod-images/EventDetail.png',
            focus: false
        },

        {
            position: 'right-top',
            src: '/prod-images/EventAnalytics.png',
            focus: false
        },

        {
            position: 'left-bottom',
            src: '/prod-images/Event.png',
            focus: false
        },

        {
            position: 'right-bottom',
            src: '/prod-images/EventGallery.png',
            focus: false
        },

        {
            position: 'center',
            src: '/prod-images/Dashboard.png',
            focus: true
        },
    ]);

    const focus = ( position: "left-top" | "left-bottom" | "right-top" | "right-bottom" | "center" ) => {
        setImages( prevImages => 
            prevImages.map( img => ({ ...img, focus: false }) )
        );

        setImages( prevImages => 
            prevImages.map( img => ({ ...img, focus: img.position === position }) )
        )
    }

    return (
        <div className='image-overlay'>
            <div className='logo'>Everything You Need All In One Place</div>

            <div className='overlay'>
                {
                    images.map( im => <img src={im.src} alt='prod-view' className={`${im.position} ${ im.focus ? 'focus' : '' } box-shadow`} onClick={ () => focus(im.position) } />  )
                }
            </div>
        </div>
    )
}