// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
  background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 20vw;
  font-family: "Inknut Antiqua"; }
`, "",{"version":3,"sources":["webpack://./src/Components/Logo/index.scss"],"names":[],"mappings":"AAAA;EACI,oEAAoE;EACpE,6BAA6B;EAC7B,qBAAqB;EACrB,kBAAkB;EAClB,eAAe;EACf,6BAA6B,EAAA","sourcesContent":[".logo {\n    background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);\n    -webkit-background-clip: text;\n    background-clip: text;\n    color: transparent;\n    font-size: 20vw;\n    font-family: \"Inknut Antiqua\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
