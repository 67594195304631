import './index.scss';

import React from 'react';

type LogoProps = {
    onClick?: ( e: any ) => void;
}

const Logo: React.FC<LogoProps> = ({ onClick }) => {
    return <div className='logo' onClick={onClick} >Cravings</div>
}

export default Logo;
