import './index.scss';
import React from 'react';

import MainDashBoardNavBar from '../../Components/NavBar/DashBoard/Main';
import UnReleased from '../../Components/UnReleased';

const DashBoard: React.FC = () => {
    return (
        <div className='DashBoardPage MainPage'>
            <MainDashBoardNavBar />
            <UnReleased date={new Date('11/14/2024')} />
        </div>
    )
}

export default DashBoard;
