// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unreleased {
  opacity: 0.9;
  display: flex;
  flex-direction: row; }
  .unreleased img {
    width: 45vw;
    height: 45vw; }
  .unreleased .container {
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .unreleased .container .title, .unreleased .container .date-text {
      font-size: 2.5vw;
      text-align: center;
      background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      font-family: "Inknut Antiqua"; }
    .unreleased .container .date-text {
      font-size: 2vw;
      margin-top: 1vw; }
    .unreleased .container .date {
      font-size: 1.5vw;
      width: fit-content;
      margin-right: auto;
      margin-left: auto;
      padding: 1vw;
      border-radius: 1vw;
      margin-top: 2vw;
      background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
      color: white; }
`, "",{"version":3,"sources":["webpack://./src/Components/UnReleased/index.scss"],"names":[],"mappings":"AAAA;EACI,YAAY;EACZ,aAAa;EACb,mBAAmB,EAAA;EAHvB;IAMQ,WAAW;IACX,YAAY,EAAA;EAPpB;IAWQ,aAAa;IACb,sBAAsB;IACtB,uBAAuB,EAAA;IAb/B;MAgBY,gBAAgB;MAChB,kBAAkB;MAClB,oEAAoE;MACpE,6BAA6B;MAC7B,qBAAqB;MACrB,kBAAkB;MAClB,6BAA6B,EAAA;IAtBzC;MA0BY,cAAc;MACd,eAAe,EAAA;IA3B3B;MA+BY,gBAAgB;MAChB,kBAAkB;MAClB,kBAAkB;MAClB,iBAAiB;MACjB,YAAY;MACZ,kBAAkB;MAClB,eAAe;MAEf,oEAAoE;MACpE,YAAY,EAAA","sourcesContent":[".unreleased {\n    opacity: 0.9;\n    display: flex;\n    flex-direction: row;\n\n    img {\n        width: 45vw;\n        height: 45vw;\n    }\n\n    .container {\n        display: flex;\n        flex-direction: column;\n        justify-content: center;\n\n        .title, .date-text  {\n            font-size: 2.5vw;\n            text-align: center;\n            background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);\n            -webkit-background-clip: text;\n            background-clip: text;\n            color: transparent;\n            font-family: \"Inknut Antiqua\";\n        }\n\n        .date-text {\n            font-size: 2vw;\n            margin-top: 1vw;\n        }\n\n        .date {\n            font-size: 1.5vw;\n            width: fit-content;\n            margin-right: auto;\n            margin-left: auto;\n            padding: 1vw;\n            border-radius: 1vw;\n            margin-top: 2vw;\n\n            background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);\n            color: white;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
