import React from 'react';

type HomeProps = {
    fill?: string;
}

const Home: React.FC<HomeProps> = ({ fill }) => {
    return (
        <svg width="69" height="61" viewBox="0 0 69 61" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M34.0821 0.838867C32.5933 0.838867 31.1057 1.31709 29.8927 2.27637L2.08049 24.269C0.165552 25.7831 -0.488848 28.3867 0.699331 30.4635C2.33455 33.3197 6.18994 33.9162 8.6774 31.947L33.2447 12.5225C33.7297 12.1391 34.4346 12.1391 34.9195 12.5225L59.4868 31.947C60.4646 32.7213 61.6534 33.097 62.8364 33.097C64.3696 33.097 65.8897 32.465 66.9262 31.2367C68.7261 29.1041 68.215 25.9541 66.002 24.2038L62.8389 21.7032V9.08701C62.8389 7.26493 61.2782 5.78678 59.3541 5.78678H55.8693C53.9439 5.78678 52.3819 7.26493 52.3819 9.08701V13.4333L38.2716 2.27637C37.0585 1.31709 35.5709 0.838867 34.0821 0.838867ZM34.077 16.8278L5.32023 39.5597V50.318C5.32023 55.783 10.0024 60.2139 15.7772 60.2139H52.3768C58.1517 60.2139 62.8338 55.783 62.8338 50.318V39.5597L34.077 16.8278ZM28.8536 35.4743H39.3106C40.755 35.4743 41.9249 36.5814 41.9249 37.9482V50.318H26.2394V37.9482C26.2394 36.5814 27.4092 35.4743 28.8536 35.4743Z" fill={fill}/>
        </svg>
    )
}

export default Home;
