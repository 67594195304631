import './index.scss';
import React from 'react';

import MainDashBoardNavBar from '../../Components/NavBar/DashBoard/Main';
import UnReleased from '../../Components/UnReleased';

const Events: React.FC = () => {
    return (
        <div className='EventsPage MainPage'>
            <MainDashBoardNavBar />
            <UnReleased date={new Date('10/30/2024')} />
        </div>
    )
}

export default Events;
