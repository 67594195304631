export const createOrgAccount = `
mutation CreateOrganizerAccount($password: String!, $email: String!, $orgName: String!) {
    CreateOrganizerAccount(password: $password, email: $email, orgName: $orgName)
}
`;

export const organizerLogin = `
mutation OrganizerLogIn($password: String!, $email: String!, $orgName: String!) {
    OrganizerLogIn(password: $password, email: $email, orgName: $orgName)
}
`;

export const reLogin = `
query Query($token: String!) {
    relogin(token: $token)
}
`;
